@import url(/node_modules/bootstrap-print-css/css/bootstrap-print.min.css);
@import url(PrintStyles.css);

@page {
    size: 5.5in 8.5in;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
span,
input,
label,
div,
button,
table,
thead,
tr,
th,
tbody,
td {
    font-family: "Gotham SSm A", "Gotham SSm B", "Montserrat", sans-serif !important;
}
div.noMargPad.row > div.row,
.noMargPad,
.noMargBot {
    margin-bottom: 0 !important;
}

.noMargBot {
    .col {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

div.noMargPad.row > div.col {
    padding: 0;
    margin: 0;
}

.rowCols {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.arrangeInlineCols {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    div {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }
    .firstChild {
        width: 300px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .normal {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            width: -webkit-min-content;
            width: -moz-min-content;
            width: min-content;
        }
        .spacer {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            text-align: end;
            width: 170px;
        }
    }
}

h1 > span {
    position: relative;
    left: 2em;
}

.heavyFont {
    font-weight: bold;
}
#root {
    p {
        margin-bottom: 16px;
        margin-bottom: 1rem;
    }
}

.border {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    --bs-gutter-x: 0px;
    --bs-gutter-x: 0rem;
    .row {
        // padding: 1em 2em 0px 3em;
        --bs-gutter-x: 0;
    }
    .mt-3 {
        .row {
            margin: 0;
        }
    }
    .col {
        padding: 1em;
    }
}

li {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.logo {
    padding: 0;
    width: 224px;
    width: 224px;
    width: 14rem;
    box-sizing: border-box;
    margin-bottom: 1.6px;
    margin-bottom: 1.6px;
    margin-bottom: 0.1rem;
}
nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 5vh;
    background: #131f33;
    color: white;
    .navLinks {
        width: 100%;
    }
    #navbarSupportedContent {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        @media screen and (max-width: 600px) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }
    }
    #navbarSupportedContent > div > div > h1 {
        display: none;
    }
    #navbarSupportedContent > div > div.active > h1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding-right: 64px;
        padding-right: 4rem;
        font-size: 20px;
        font-size: 20px;
        font-size: 1.25rem;
        margin: 0.3em 0em;
        width: 100%;
    }
}
.navbar-brand {
    padding: 0 48px 0 14.4px;
    padding: 0 48px 0 14.4px;
    padding: 0 3rem 0 0.9rem;
}
.navBar {
    margin-bottom: 1em;
}
.navbar {
    padding: 0.7em 1em;
    margin-bottom: 1em;
}
.printHeader h1 {
    display: none;
}

div.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.verticalCenter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.flexBasis {
    -ms-flex-preferred-size: content;
    flex-basis: content;
}
.row {
    margin-bottom: 1em;
}

.maxWidth {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100% !important;
}

h3 {
    background-color: #ccc;
    padding: 4px;
    padding: 4px;
    padding: 4px;
    padding: 0.25rem;
}

.dollarAmount .dateField {
    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
        top: -10px;
    }
    fieldset {
        border: none;
    }
    border: solid;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

span.lineUp,
.lineUp {
    font-weight: normal;
}
.rightLabel,
span.lineUp,
.lineUp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    @media only screen and (min-device-width: 600px) {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        text-align: right;
        width: 335px !important;
    }
    @media only print {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.leftLabel {
    @media only screen and (min-device-width: 600px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        max-width: 130px;
    }
}

.resultItems {
    li {
        border: none;
    }
    .row {
        margin-bottom: 0;
    }

    .results {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    @media screen and (max-width: 600px) {
        .row {
            margin-bottom: 0.5em;
        }
    }
}

span.normal {
    font-weight: bold;
    padding: 0em 1em;
}

.headerPrint {
    display: none;
}

.rdbld {
    font-weight: bold;
    color: red;
}

.PhoneInput {
    border: solid 1px #cccccc;
    padding: 14.4px;
    padding: 14.4px;
    padding: 14.4px;
    padding: 0.9rem;
    border-radius: 4px;
    .PhoneInputInput {
        border: none;
    }
}

.PhoneInputInput {
    :focus-visible {
        border: none;
    }
}

.addressSpace {
    margin-left: 0.8px;
    margin-left: 0.8px;
    margin-left: 0.8px;
    margin-left: 0.05rem;
}
.btn-primary {
    background-color: #131f33;
}
.addButton {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.addButtonIcon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #131f33;
}
.MuiButton-sizeMedium.MuiButton-containedSizeMedium,
button,
.buttonClass {
    background-color: #131f33;
    &:hover {
        background-color: #cccccc;
    }
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
    background-color: #cccccc;
}

.btn-primary:hover {
    border-color: transparent;
}

.end-align {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.dollarAmount {
    input {
        text-align: right;
        padding-right: 4px;
        padding-right: 16px;
        padding-right: 16px;
        padding-right: 1rem;
    }
}

.dollarAmountLabel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.desigColTitle {
    @media only screen and (max-device-width: 600px) {
        margin-left: 0em;
    }
    @media only print {
        margin-bottom: 0;
    }
    margin-left: 5em;
}

.hideForPrint {
    @media only screen and (min-device-width: 600px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: left;
    }
    @media only print {
        display: none;
    }
}

.singular-offset {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    .colOne {
        width: 50%;
    }
    .rightLabel {
        padding-right: 1.25em;
    }
}

// fix for width on gift total on cashprint
.giftTotal {
    min-width: 100px;
}

.d-print-table-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    // align-items: center;
}

.normal.longName {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 450px;
}

.totalTable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.total {
    width: 33%;
    padding: 10px;
    text-align: right;
}

.css-1wc848c-MuiFormHelperText-root {
    position: absolute;
    left: 165px;
    width: 300px;
}

.hiddenText {
    display: none;
}

.d-print-table-cell {
    width: 50%;
    .normal {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

.justify-col-tribute {
    position: relative;
    left: 0em;
}

.IsTributeNewAddressChecked {
    position: relative;
    left: 0em;
}

.centerColContent {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#contactPrint {
    padding: 0.5em 0em;
    text-align: left;
    .rightLabel {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-end;
        text-align: left;
        min-width: 520px !important;
        padding: 0 0.5em;
        .normal {
            width: 250px;
        }
    }
}

.topBottomMargin {
    margin: 1em 0;
}

.list-group-item {
    border-radius: 4px;
}
.list-group-item + .list-group-item {
    border-top-width: 1px;
}

div.col-md-2.col-12,
div.col-md-3.col-12,
div.col-md-4.col-12,
div.col-md-5.col-12,
div.col-md-6.col-12 {
    @media screen and (max-width: 600px) {
        padding-bottom: 1em;
    }
}

.campusAddress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 90px;
}

.backButton {
    margin-right: 2em;
}

.submitRow {
    padding-top: 1em;
    padding-right: 1em;
    .displayContents {
        display: contents;
    }
    .theSubmit {
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
    }
}

.indent-list {
    position: relative;
    left: 2em;
    li {
        left: 0px;
        div {
            padding-right: 2em;
        }
    }
}
.d-print-table-cell.d-flex.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.d-print-table-cell {
    .normal {
        display: block;
    }
}

.printTable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    table {
        width: 75%;
        td {
            font-weight: bold;
        }
    }
}
.tableIsh {
    margin: 0 2rem;
}

div.row.tableIsh > div:nth-child(1),
div.row.tableIsh > div:nth-child(2) {
    border-bottom: solid 1px #ccc;
    margin: 0 2rem;
}

div.row.tableIsh:last-of-type {
    .col {
        border-bottom: 0px none;
    }
}

#timestamp {
    display: none;
}

.overFlowBreakWord {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.overFlowNoBreakWord {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.innerBorder {
    max-width: 91.75vw;
    position: relative;
    margin: 1em auto;
}

.vCenter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.rightOffset {
    position: relative;
    right: 6em;
}

#donationDate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    text-align: end;
}

.applicationInfo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pGiftTable {
    td {
        width: 33.3333%;
    }
}
.borderTop {
    border-top: solid 1px #ccc;
    margin: 0rem 1rem;
}
.borderBtm:nth-child(even) {
    border-bottom: solid 1px #ccc;
}

.trustPrintBtn {
    padding: 1em;
}

th,
td {
    text-align: center !important;
}
/* Viewports between 320px and 480px wide */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .backButton {
        width: 100%;
        height: 100%;
    }
    .d-print-table-row {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    #contactPrint .rightLabel {
        min-width: 500px !important;
    }
    .donorPrintStuff {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    #donationDate {
        min-width: 510 !important;
    }
}

.rightFix {
    padding-right: 3.5rem !important;
}

.tribOppEmail {
    padding-left: 3.5rem;
}
